
  import Vue from 'vue';
  import PwaMinigameHeader from "@/components/game/PwaMinigameHeader.vue";
  import PwaDepositBanner from "@/components/deposit/PwaDepositBanner.vue";
  import {COMPETITION_TYPE_IDS, RegisterCompetitionErrorKey} from "@/utils/competition";
  import {BreadcrumbItems, BreadcrumbItemType} from "@/utils/breadcrumb";
  import pageMixin from "@/mixins/page";
  import {EVENTS} from "@/utils/events";
  import {MetaInfo} from "vue-meta";
  import {ROUTES} from "@/router/routes";
  import { MINIGAME_PAGE_SUBTITLE_LABEL } from "@/utils/seo";
  import {isPrerender} from "@/utils/common";
  import PwaPracticeGame from '@/components/competitions/PwaPracticeGame.vue';
  import PwaUserActive from "@/components/user/PwaUserActive.vue";
  import PwaGameUsersRankings from "@/components/game/PwaGameUsersRankings.vue";
  import PwaNoCompetitions from "@/components/competitions/PwaNoCompetitions.vue";
  import PwaCompetitionCard from "@/components/competitions/PwaCompetitionCard.vue";
  import PwaMultiplayerCard from "@/components/competitions/PwaMultiplayerCard.vue";
  import PwaPrice from "@/components/common/PwaPrice.vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import PwaUserLevel from "@/components/user/PwaUserLevel.vue";
  import {MODAL_IDS} from "@/utils/modal";
  import { showInterstitial, showRewarded } from '@/services/ads';
  import PwaInscriptionNoMoney from "@/components/modals/competitions/PwaInscriptionNoMoney.vue";
  import PwaCreateIndividualCompetitionModal from "@/components/modals/competitions/PwaCreateIndividualCompetitionModal.vue";
  import {UserActionTypes} from "@/store/modules/user/actions";
  import PwaPlayGame from "@/components/competitions/PwaPlayGame.vue";
  import PwaResultIndividualCompetitionModal from "@/components/modals/competitions/PwaResultIndividualCompetitionModal.vue";
  import { MINIGAMES_ALIAS } from '@/utils/minigames';

  const TABS = {
    MATCHES: 0,
    GAMECHAT: 1,
  };

  const usersLimit = 4;
  const rewardVideoTimeout = 5000;

  type IndividualAwardType = {points: number, multiplier: number, min_cost_inscription?: number, max_cost_inscription?: number, default_cost_inscription?: number};

  export default Vue.extend({
    name: "Minigame",
    mixins: [pageMixin],
    components: {
      PwaMinigameHeader,
      PwaDepositBanner,
      PwaUserActive,
      PwaCompetitionCard,
      PwaMultiplayerCard,
      PwaNoCompetitions,
      PwaGameUsersRankings,
      PwaUserLevel,
      PwaPrice,
      PwaIcon,
      PwaCrossSellingBanner: () => import('@/components/PwaCrossSellingBanner.vue'),
      PwaChatContainer: () => import("@/components/chat/PwaChatContainer.vue"),
      PwaGamesSlider: () => import("@/components/game/PwaGamesSlider.vue"),
      PwaWebAdBillboardBanner: () => import('@/components/ads/PwaWebAdBillboardBanner.vue'),
      PwaWebAdLeaderboardBanner: () => import('@/components/ads/PwaWebAdLeaderboardBanner.vue'),
      PwaWebAdHalfPageBanner: () => import('@/components/ads/PwaWebAdHalfPageBanner.vue'),
      PwaWebAdInlineRectangleBanner: () => import('@/components/ads/PwaWebAdInlineRectangleBanner.vue'),
    },
    data() {
      return {
        wagers: [],
        multiplayers: [],
        gameData: this.getPageInitialData('gameData', {}) as any,
        minigames: [],
        totalPracticeAttempts: 0,
        numPracticeAttempts: 0,
        bannerData: {} as any,
        //Paginacion
        competitionsOffset: 0,
        competitionsLimit: 8,
        userLevels: [],
        rankings: null,
        users: [],
        hasMoreUsers: false,
        seeGameLevelsInfo: false,
        hasMoreWagers: false,
        hasMoreMultiplayers: false,
        tabs: TABS,
        selectedTab: TABS.MATCHES,
        loadMobileChat: false,
        chatData: null,
        rewardDone: false,
        individualAwardIndexSelected: null as number,
        individualAwardsData: null as {cost_inscription_increment: number, awards: IndividualAwardType[]},
        individualCostInscription: null as number,
        numIndividualAwardsToShow: 2,
        individualAwardDefault: [
          {points: null, multiplier: null},
          {points: null, multiplier: null},
          {points: null, multiplier: null},
          {points: null, multiplier: null},
        ] as IndividualAwardType[],
        competitionsFreeInscriptions: [],
      }
    },
    mounted(): void {
      if(this.isMobile){
        this.competitionsLimit = 3;
      }else if(this.isTablet){
        this.competitionsLimit = 4;
      }else{
        this.numIndividualAwardsToShow = 4;
      }
      this.individualAwardDefault = this.individualAwardDefault.slice(0, this.numIndividualAwardsToShow);
      this.getMinigamePage();
      this.$root.$on(EVENTS.RELOAD_MINIGAME_PAGE, this.reloadPage);
      if (!isPrerender) {
        this.removePageInitialData();
      }

      this.$root.$on(EVENTS.ON_PLAY_GAME_MODAL_CLOSE, ({score, winScore}: {score: number, winScore: number}) => {
        if (winScore && score) {
          const message = this.$createElement(PwaResultIndividualCompetitionModal, {
            props: {
              score,
              winScore,
            },
          });
          setTimeout(() => {
            this.showModal({
              id: MODAL_IDS.RESULT_INDIVIDUAL_COMPETITION,
              message,
              noCloseOnBackdrop: true,
              noCloseOnEsc: true,
              showOnRoot: true,
              contentClass: {'is-desktop': this.isDesktop, 'hide-footer': true, 'no-money': true},
            });
          }, 300);
        }
      });
    },
    beforeDestroy(): void {
      this.$root.$off(EVENTS.RELOAD_MINIGAME_PAGE);
    },
    methods: {
      async getMinigamePage() {
        try {
          this.showLoader(true);
          let {data} = await this.$http.page.getMinigamePage(this.gameAlias, {});
          const gameData = data.data.game_data;
          this.chatData = data.data.chat_data;
          this.gameData = gameData;
          if (isPrerender) {
            this.savePageInitialData({gameData})
          }
          if(this.isWinnder && this.isLoggedIn){
            this.getIndividualAwardsData();
          }
          await Promise.all([
            this.getWagersFiltered(false, false),
            this.getMultiplayerFiltered(false, false),
          ]);
          this.minigames = data.data.minigames || [];
          this.totalPracticeAttempts = data.data.total_practice_attempts || 0;
          this.numPracticeAttempts = data.data.num_practice_attempts || 0;
          this.bannerData = data.data.banner_data || {};
          this.userLevels = data.data.user_levels || [];
          this.rankings = data.data.rankings;
          this.users = data.data.users;
          this.hasMoreUsers = this.users.length >= usersLimit;
          this.competitionsFreeInscriptions = data.data.competitions_free_inscriptions || [];
        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      onSeeMoreWagersClick() {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          if (this.hasMoreWagers) {
            this.getWagersFiltered(true);
          }
        }
      },
      async getWagersFiltered(paginate = false, showLoader = true) {
        if (showLoader) {
          this.showLoader(true);
        }
        const competitionsOffset = paginate ? this.wagers.length : 0;
        const params: any = {
          game_id: this.gameData.id,
          type_id: COMPETITION_TYPE_IDS.WAGER,
          limit: this.competitionsLimit,
          offset: competitionsOffset,
        };

        try {
          const {data} = await this.$http.competition.postCompetitionsFilter(params);
          const wagers: Record<string, any>[] = data.data;
          if (this.wagers.length && paginate) {
            wagers.forEach(wager => this.wagers.push(wager));
          } else {
            this.wagers = wagers;
          }
          this.hasMoreWagers = (wagers.length >= this.competitionsLimit);
        } catch (error) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          if (showLoader) {
            this.showLoader(false);
          }
        }
      },
      async getMultiplayerFiltered(paginate = false, showLoader = true) {
        if (showLoader) {
          this.showLoader(true);
        }
        const competitionsOffset = paginate ? this.multiplayers.length : 0;
        const params: any = {
          game_id: this.gameData.id,
          type_id: COMPETITION_TYPE_IDS.MULTIPLAYER,
          limit: this.competitionsLimit,
          offset: competitionsOffset,
        };

        try {
          const {data} = await this.$http.competition.postCompetitionsFilter(params);
          const multiplayers: Record<string, any>[] = data.data;
          if (this.multiplayers.length && paginate) {
            multiplayers.forEach(multiplayers => this.multiplayers.push(multiplayers));
          } else {
            this.multiplayers = multiplayers;
          }
          this.hasMoreMultiplayers = (multiplayers.length >= this.competitionsLimit);
        } catch (error) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          if (showLoader) {
            this.showLoader(false);
          }
        }
      },
      async getIndividualAwardsData() {
        try {
          const {data} = await this.$http.competition.getIndividualsGameUserAwards(this.gameAlias);
          const individualAwardsData = data.data || null;
          if (individualAwardsData) {
            const fakeAwards = this.numIndividualAwardsToShow - individualAwardsData.awards.length;
            if (fakeAwards > 0) {
              for (let i = 0; i < fakeAwards; i++) {
                individualAwardsData.awards.push({
                  points: null,
                  multiplier: null,
                });
              }
            }
          }
          this.individualAwardsData = individualAwardsData;
          if(this.individualAwardsData){
            this.selectIndividualAward(0);
          }else{
            this.individualAwardIndexSelected = null;
            this.individualCostInscription = null;
          }
        } catch (e) {
          // Nothing to do
        }
      },
      onSeeMoreMultiplayersClick() {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          if (this.hasMoreMultiplayers) {
            this.getMultiplayerFiltered(true);
          }
        }
      },
      onClickPracticeGame() {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          this.rewardDone = false;
          if(this.isDesktop){
            const message = this.$createElement(PwaPracticeGame, {
              props: {
                isModal: true,
                gameAlias: this.gameAlias,
              },
            });
            const title = this.$t('574');
            this.showModal({
              id: MODAL_IDS.MODAL_PRACTICE_GAME,
              title,
              message,
              hideCancel: true,
              noCloseOnBackdrop: true,
              noCloseOnEsc: true,
              dialogClass: 'game-container',
              headerClass: 'title-centered',
              contentClass: 'create-match-background',
              footerClass: 'buttons-hidden',
            });
          }else{
            const params: Record<string, any> = {};
            params.gameAlias = this.gameAlias;
            this.$router.push({name: ROUTES.practiceGame.name, params: {...params, lang: this.routeLang}});
          }
        }
      },
      onClickGetPracticeGame() {
        const onRewarded = () => {
          if (!this.rewardDone) {
            this.showLoader(false);
            this.rewardDone = true;
          }
        };

        const onRewardedError = () => {
          showInterstitial(true).then(showInterstitialSuccess => {
            if (showInterstitialSuccess) {
              onRewarded();
            }
          });

          // Fallback por si acaso tarda mucho
          setTimeout(() => {
            onRewarded();
          }, rewardVideoTimeout);
        };

        showRewarded().then(showRewardedSuccess => {
          if (showRewardedSuccess) {
            onRewarded();
          } else {
            onRewardedError();
          }
        });

        this.showLoader(true);
      },
      reloadPage() {
        //Paginacion
        this.competitionsOffset = 0;
        this.getMinigamePage();
      },
      onSeeMoreUsersClick() {
        if(!this.isLoggedIn){
          this.goToSignup();
        }else{
          if (this.hasMoreUsers) {
            this.getMoreUsersToChallenge();
          }
        }
      },
      async getMoreUsersToChallenge() {
        this.showLoader(true);
        const params: any = {
          limit: usersLimit,
          offset: this.users.length,
          game_id: this.gameData.id
        };

        try {
          const {data} = await this.$http.user.getUsersToChallange(params);
          const users: Record<string, any>[] = data.data || [];
          users.forEach(user => this.users.push(user));
          this.hasMoreUsers = (users.length >= usersLimit);
        } catch (error) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      onSeeGameLevelsInfoClick() {
        this.seeGameLevelsInfo = !this.seeGameLevelsInfo;
      },
      onTabChange(tabSelected: number) {
        if (tabSelected === TABS.GAMECHAT && !this.loadMobileChat) {
          this.loadMobileChat = true;
        }
      },
      selectIndividualAward(individualAwardIndex: number) {
        const award: IndividualAwardType = this.individualAwardsData.awards[individualAwardIndex];
        if (award.points !== null) {
          this.individualCostInscription = award.default_cost_inscription;
          this.individualAwardIndexSelected = individualAwardIndex;
        }
      },
      individualCostInscriptionDecreaseClick() {
        const costInscriptionIncrement = this.individualAwardIndexSelected !== null && this.individualCostInscription > this.individualAwardsData.awards[this.individualAwardIndexSelected].min_cost_inscription
          ? this.individualAwardsData.cost_inscription_increment
          : 0;

        if (costInscriptionIncrement) {
          this.individualCostInscription = this.individualCostInscription - costInscriptionIncrement;
        }
      },
      individualCostInscriptionIncreaseClick() {
        const costInscriptionIncrement = this.individualAwardIndexSelected !== null && this.individualCostInscription < this.individualAwardsData.awards[this.individualAwardIndexSelected].max_cost_inscription
          ? this.individualAwardsData.cost_inscription_increment
          : 0;

        if (costInscriptionIncrement) {
          this.individualCostInscription = this.individualCostInscription + costInscriptionIncrement;
        }
      },
      onIndividualAwardCreateClick() {
        const competitionFreeInscription = this.competitionsFreeInscriptions.find(({ cost_inscription }) => cost_inscription == this.individualCostInscription);
        if (!competitionFreeInscription && this.walletQuantity < this.individualCostInscription) {
          this.showIndividualPopupNoMoney();
        } else {
          const message = this.$createElement(PwaCreateIndividualCompetitionModal, {
            props: {
              award: this.individualAwardsData.awards[this.individualAwardIndexSelected],
              costInscription: this.individualCostInscription,
              gameAlias: this.gameAlias,
              competitionsFreeInscriptionsId: competitionFreeInscription ? competitionFreeInscription.id : 0,
              onAcceptClick: (competitionsFreeInscriptionsId: number) => this.onIndividualAwardAcceptCreateClick(competitionsFreeInscriptionsId),
            },
          });
          this.showModal({
            id: MODAL_IDS.CREATE_INDIVIDUAL_COMPETITION,
            message,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            showOnRoot: true,
            contentClass: {'is-desktop': this.isDesktop, 'hide-footer': true, 'no-money': true},
          });
        }
      },
      async onIndividualAwardAcceptCreateClick(competitionsFreeInscriptionsId: number) {
        try {
          this.showLoader(true);
          const award = this.individualAwardsData.awards[this.individualAwardIndexSelected];
          const postData = {
            game_alias: this.gameAlias,
            cost_inscription: this.individualCostInscription,
            award,
            competitions_free_inscriptions_id: competitionsFreeInscriptionsId,
          };
          const { data } = await this.$http.competition.postCreateIndividualCompetition(this.userId, postData);
          const competitionId = data.data.competitionId || null;
          const resultId = data.data.resultId || null;
          if (competitionId && resultId) {
            this.onRegisterUserIndividualSuccess(competitionId, resultId, award.points);
          } else {
            this.showLoader(false);
            this.showToastError(this.$t('139'), this.$t('140'));
          }
        } catch ({response}) {
          this.showLoader(false);
          const errorData = response?.data || {};
          const key = errorData?.key;
          const {NO_MONEY, AWARD_NOT_AVAILABLE} = RegisterCompetitionErrorKey;
          if (key === NO_MONEY) {
            this.showIndividualPopupNoMoney();
          } else if (key === AWARD_NOT_AVAILABLE) {
            this.showToastError(this.$t('139'), this.$t('761'));
            this.reloadPage();
          } else {
            const errorMessage = errorData?.message || errorData?.errors || this.$t('140');
            this.showToastError(this.$t('139'), errorMessage);
          }
        }
      },
      async onRegisterUserIndividualSuccess(competitionId: number, resultId: number, winScore: number) {
        this.$store.dispatch(UserActionTypes.UPDATE_USER_WALLET);
        await showInterstitial();
        this.showLoader(false);
        if (this.isDesktop) {
          const message = this.$createElement(PwaPlayGame, {
            props: {
              isModal: true,
              gameAlias: this.gameAlias,
              competitionId: competitionId,
              resultId: resultId,
              winScore,
            },
          });
          const title = this.$t('549');
          this.showModal({
            id: MODAL_IDS.MODAL_PLAY_GAME,
            title,
            message,
            hideCancel: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            dialogClass: 'game-container',
            headerClass: 'title-centered',
            contentClass: 'create-match-background',
            footerClass: 'buttons-hidden',
          });
        } else {
          const params: Record<string, string|number> = {
            gameAlias: this.gameAlias,
            competitionId,
            resultId,
            winScore,
          };
          this.routerPush(ROUTES.playGame.name, params);
        }
      },
      showIndividualPopupNoMoney() {
        const message = this.$createElement(PwaInscriptionNoMoney, {
          props: {
            create: false,
            costInscription: this.individualCostInscription,
          },
        });
        this.showModal({
          id: 'modal-no-money',
          message,
          showOnRoot: true,
          contentClass: {'no-money': true, 'is-desktop': this.isDesktop, 'hide-footer': true},
        });
      },
    },
    computed: {
      gameAlias(): string {
        return this.$route.params.gameAlias || '';
      },
      gameId(): number {
        return this.gameData.id || 0;
      },
      breadcrumb(): BreadcrumbItemType[] {
        return [
          BreadcrumbItems.lobby,
          {text: this.gameData.name || '-'},
        ];
      },
      title(): string {
        return this.$t('463', {gameName: this.gameData.name});
      },
      description(): string {
        return this.$t('464', {gameName: this.gameData.name});
      },
      subtitleText(): string {
        const label = (MINIGAME_PAGE_SUBTITLE_LABEL as any)[this.gameAlias] || '';
        return label ? this.$t(label, {appName: this.appName}) : this.$t('426', {gameName: this.gameData.name, appName: this.appName});
      },
      metaData(): MetaInfo {
        return {
          title: this.title,
          titleTemplate: (titleChunk) => {
            return this.$t('742', {gameName: this.gameData.name});
          },
          meta: [
            {name: 'description', content: this.description},
            {name: 'robots', content: 'index'}
          ],
          link: this.getMetaDataPathLinks(ROUTES.minigame.path.replace(':gameAlias', this.gameAlias)),
        };
      },
      individualAwardAmount(): number {
        return this.individualAwardIndexSelected !== null
          ? (this.individualCostInscription * this.individualAwardsData.awards[this.individualAwardIndexSelected].multiplier)
          : 0;
      },
      individualAwardsList(): IndividualAwardType[] {
        return this.individualAwardsData === null
          ? this.individualAwardDefault
          : this.individualAwardsData.awards;
      },
      isMysteryMinigame(): boolean{
        return this.gameAlias == MINIGAMES_ALIAS.MYSTERY;
      }
    },
    watch: {
      gameAlias() {
        this.reloadPage();
      },
    }
  });
