import { render, staticRenderFns } from "./PwaCreateIndividualCompetitionModal.vue?vue&type=template&id=966ce9ea&scoped=true&"
import script from "./PwaCreateIndividualCompetitionModal.vue?vue&type=script&lang=ts&"
export * from "./PwaCreateIndividualCompetitionModal.vue?vue&type=script&lang=ts&"
import style0 from "./PwaCreateIndividualCompetitionModal.vue?vue&type=style&index=0&id=966ce9ea&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "966ce9ea",
  null
  
)

export default component.exports