import { render, staticRenderFns } from "./Minigame.vue?vue&type=template&id=40624585&scoped=true&"
import script from "./Minigame.vue?vue&type=script&lang=ts&"
export * from "./Minigame.vue?vue&type=script&lang=ts&"
import style0 from "./Minigame.vue?vue&type=style&index=0&id=40624585&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40624585",
  null
  
)

export default component.exports