
  import Vue, {PropType} from 'vue';
  import { MODAL_IDS } from "@/utils/modal";
  import PwaPrice from "@/components/common/PwaPrice.vue";

  export default Vue.extend({
    name: 'pwa-create-individual-competition-modal',
    components: {
      PwaPrice,
    },
    props: {
      award: {
        type: Object as PropType<{points: number, multiplier: number}>,
        required: true,
      },
      costInscription: {
        type: Number,
        required: true,
      },
      gameAlias: {
        type: String,
        required: true,
      },
      competitionsFreeInscriptionsId: {
        type: Number,
        required: false,
        default: 0,
      },
      onAcceptClick: {
        type: Function as PropType<(competitionsFreeInscriptionsId: number) => void>,
        required: true,
      },
    },
    computed: {
      awardAmount(): number {
        return this.costInscription * this.award.multiplier;
      },
    },
    methods: {
      onCreateClick(competitionsFreeInscriptionsId = 0) {
        this.closeModal();
        this.onAcceptClick(competitionsFreeInscriptionsId);
      },
      onCancelClick() {
        this.closeModal();
      },
      closeModal() {
        this.hideModalById(MODAL_IDS.CREATE_INDIVIDUAL_COMPETITION);
      },
    }
  });
